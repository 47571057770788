<template>
	<div>
		<form-remote-select name="status"
							v-model="props.data.status"
							:disabled="props.disabled"
							:color="props.color"
							:placeholder="t('label.ranked')"
							:label="t('label.status')"
							:action="'/v1/participation-status'"
							:data="{type: 'manual_participation_results'}"
							as-guest
							:filterable="false"
							text-prop="title"
							value-prop="value"
							help-prop="description"/>

		<form-input v-if="props.edit" v-model="props.data.starter_number" name="starter_number"
					:rules="{min: 1, max: 8}"
					:disabled="props.disabled"
					:placeholder="t('label.unknown')"
					:label="t('label.starter_number')"/>

		<template v-if="!props.data.status || props.data.status === ParticipationStatus.Ranked">
			<dynamic-form-input v-if="props.participationClass.rank_metric && rankMetricInputData[props.participationClass.rank_metric]" v-model="props.data[props.participationClass.rank_metric]"
								:name="props.participationClass.rank_metric"
								:label="rankMetricInputData[props.participationClass.rank_metric].label"
								:type="rankMetricInputData[props.participationClass.rank_metric].type"
								:input-props="rankMetricInputData[props.participationClass.rank_metric].props"
								:placeholder="rankMetricInputData[props.participationClass.rank_metric].placeholder"
								:disabled="props.disabled"/>

			<template v-if="props.withRanks">
				<form-number-input v-model="props.data.overall_rank"
								   name="overall_rank"
								   no-separators
								   :disabled="props.disabled"
								   :placeholder="t('label.unknown')"
								   :label="t('label.overall_rank')"
								   :help="t('help.overall_rank')"/>

				<form-number-input v-if="props.gender !== undefined"
								   v-model="props.data.gender_rank"
								   name="gender_rank"
								   no-separators
								   :disabled="props.disabled || (props.gender === null)"
								   :placeholder="props.gender === null ? t('label.missing_gender') : t('label.unknown')"
								   :label="t('label.gender_rank')"
								   :help="t('help.gender_rank')">
					<template v-if="props.gender" #suffix>
						<span class="text-gray-400 text-sm mr-8">{{ props.gender === 'm' ? t('label.male') : t('label.female') }}</span>
					</template>
				</form-number-input>

				<form-number-input v-if="props.ageClass !== undefined && props.gender !== undefined"
								   v-model="props.data.age_class_rank"
								   name="age_class_rank"
								   no-separators
								   :disabled="props.disabled || (props.ageClass === null)"
								   :placeholder="props.ageClass === null ? t('label.missing_birthday') : t('label.unknown')"
								   :label="t('label.age_class_rank')"
								   :help="t('help.age_class_rank')">
					<template v-if="props.ageClass" #suffix>
						<span class="text-gray-400 text-sm mr-8">{{ props.ageClass.name }}</span>
					</template>
				</form-number-input>
			</template>
		</template>

		<form-codearea :label="t('label.result_info')" v-model="props.data.result_info"
					   :disabled="props.disabled"
					   no-media no-headings
					   :placeholder="t('help.participation.result_info')"
					   :help="t('help.participation.result_info')"/>

	</div>
</template>

<script setup lang="ts">
import {Model, ParticipationStatus} from "@spoferan/spoferan-ts-core";

const {t} = useI18n();
const {$apiFetch} = useNuxtApp();

const props = defineProps<{
	// The data on which to store the input data
	data: { [key: string]: any },

	// Whether all inputs shall be disabled
	disabled?: boolean,

	edit?: boolean,

	// Whether to display manual rank inputs when the participation is ranked
	withRanks?: boolean,

	// The age class the participation is assigned to
	ageClass?: object|null,

	// The gender of the participation
	gender?: string|null,

	color?: string,

	participationClass: Model
}>();

const rankMetricInputData = {
	'time': {
		type: 'input',
		props: {mask: '##:##:##.###'},
		label: t('enum.rank_metric.time.title'),
		placeholder: 'hh:mm:ss.SSS'
	},
	'distance': {
		type: 'number',
		label: `${t('enum.rank_metric.distance.title')} (${t('label.meters')})`,
		props: {
			suffix: t('label.meters')
		}
	},
	'altitude': {
		type: 'number',
		label: `${t('enum.rank_metric.altitude.title')} (${t('label.meters')})`,
		props: {
			suffix: t('label.meters')
		}
	},
	'rounds': {
		type: 'number',
		label: t('enum.rank_metric.rounds.title'),
		placeholder: t('label.without_rounds')
	},
	'points': {
		type: 'number',
		label: t('enum.rank_metric.points.title')
	},
}

</script>